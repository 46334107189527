<template>
  <div>
    <Section1 :keyWord="'Consigue desde 600€ de forma rápida con la garantía de tu coche'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Micro préstamos con asnef</strong></h2>
      <p>Te presentamos los nuevos <strong>préstamos con asnef de Ibancar.</strong> El sistema más rápido y seguro para disponer de liquidez en tu cuenta en un tiempo récord y completando todo el proceso de manera online. ¿Todavía no has solicitado el tuyo?</p>
      <p>¿Puedes conseguir un préstamo urgente en estas circunstancias? La respuesta es sí. Y la solución se llama <strong>micro préstamos con asnef</strong> en Ibancar. A continuación, todos los detalles.</p>

      <h3 class="naranja"><strong>Préstamos con ASNEF</strong></h3>
      <p>Las entidades bancarias tradicionales consultan los ficheros de la Asnef antes de conceder un préstamo a sus clientes. Si tu nombre aparece en el listado, se limitan a denegar el crédito sin molestarse en conocer el origen y la razón de la inclusión.</p>
      <p>En Ibancar esta circunstancia no es determinante a la hora de conceder un préstamo. Sus <strong>micro préstamos con asnef</strong> son concedidos a todo tipo de clientes con tan solo presentar la documentación necesaria para demostrar que poseen una fuente de ingresos.</p>
      <p>Pero lo mejor de todo es que estos <strong>micro préstamos con asnef</strong> se pueden tramitar de manera 100% online, en cuestión de minutos y con la seguridad de que el dinero será depositado en la cuenta del cliente en menos de 24 horas. ¡Así de fácil!</p>

      <h3 class="naranja"><strong>Los créditos con ASNEF como alternativa</strong></h3>
      <p>Los <strong>micro préstamos con asnef</strong> son una alternativa perfecta a los créditos tradicionales. El proceso para la solicitud y concesión de un crédito en una entidad bancaria tradicional se puede volver eterno.</p>
      <p>Además, si la persona solicitante está incluida en los ficheros de asnef, lo más probable es que el crédito le sea denegado</p>

      <h3 class="naranja"><strong>Opiniones de los usuarios que solicitaron préstamos personales con ASNEF</strong></h3>
      <p>Las personas que han solicitado <strong>micro préstamos con asnef</strong> confiesan que esta opción les ha permitido solucionar un problema económico o ganar liquidez para iniciar un proyecto de manera rápida, cómoda y flexible. Lo que más valoran:</p>
      <ul>
          <li>Proceso 100% online</li>
          <li>Propuesta de crédito a medida</li>
          <li>Asesoramiento personalizado</li>
          <li>Facilidad para completar la solicitud en cuestión de minutos</li>
          <li>Dinero en la cuenta en menos de 24 horas</li>
      </ul>

      <h3 class="naranja"><strong>Preguntas frecuentes de los mini préstamos con ASNEF</strong></h3>
      <p><strong>¿Qué son los préstamos con ASNEF?</strong></p>
      <p>La modalidad de micro préstamos con asnef es un servicio que tiene como objetivo facilitar el crédito a personas incluidas en las listas de impagados de Asnef.</p>
      <p><strong>Cómo solicitar créditos online con ASNEF</strong></p>
      <p>Solicitar micro créditos con asnef en Ibancar es muy fácil:</p>
      <ul>
          <li>Rellenas un sencillo formulario con la cantidad solicitada</li>
          <li>Recibes una propuesta de crédito personalizada</li>
          <li>Aceptas la propuesta, aportas la documentación necesaria y recibes el dinero en la cuenta en menos de 24 horas.</li>
      </ul>
      <p><strong>¿Qué es un préstamo con ASNEF urgente?</strong></p>
      <p>Los <strong>micro préstamos con asnef</strong> son créditos que se conceden a las personas incluidas en los ficheros de impagados de asnef. Ibancar ofrece esta solución de manera 100% online, lo que facilita y agiliza todavía más el proceso.</p>

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      
      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-madrid" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-sevilla" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
              </div>
            </a>
          </div>
      </div>

      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-las-palmas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
    
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'MicroPrestamosAsnef',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Micro préstamos con asnef ¡Fácil! | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Necesitas dinero urgente, no tienes tiempo para esperar por todos los trámites que conlleva solicitar un crédito en una entidad bancaria y además te has enterado de que has sido incluido en los ficheros de impagados de Asnef.'},
        {name:'keywords', content:'micro préstamos con asnef'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>